// Here you can add other styles
.smartTable {
    table thead{
      tr:last-child {
        background-color: #e8e8e8;
      }
      td {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
      }
    }
  
    thead {
      background-color: #cfb3d8 !important;
    }
    thead th {
      color: white;
    }
    input {
      padding: 0.1rem 0.1rem !important;
      min-height: calc(0.8em + 0.5rem + 2px) !important;
    }
    thead th span {
      // position: absolute;
      opacity: .8 !important;
      top: 3px;
      right: 0;
    }
    .details {
      text-transform: capitalize;
    }
    .details > div span {
      display: inline-block;
      width: 150px;
    }
  }
  .smartTable1 {
    table thead{
      tr:last-child {
        background-color: #cfb3d8;
      }
      td {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
      }
    }
  
    thead {
      background-color: #cfb3d8 !important;
    }
    thead th {
      color: white;
    }
    input {
      padding: 0.1rem 0.1rem !important;
      min-height: calc(0.8em + 0.5rem + 2px) !important;
    }
    thead th span {
      // position: absolute;
      opacity: .8 !important;
      top: 3px;
      right: 0;
    }
    .details {
      text-transform: capitalize;
    }
    .details > div span {
      display: inline-block;
      width: 150px;
    }
  }